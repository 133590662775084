import { Avatar, Card, CardBody, Flex, Heading, Text } from '@chakra-ui/react';

function TestimonialCard({ imageSource, name, description }) {
    return (
        <Card minHeight='40' p='4' borderRadius='12' boxShadow={'none'}>
            <CardBody w='100%'>
                <Flex
                    direction={{ base: 'column-reverse', md: 'row' }}
                    width={'full'}
                    p={10}
                    justifyContent={'space-between'}
                    _after={{
                        content: 'url("/images/icons/quote.svg")',
                        position: 'absolute',
                        left: '35px',
                        top: '-1.5rem',
                        backgroundSize: 'cover',
                    }}
                >
                    <Flex
                        direction={'column'}
                        textAlign={'left'}
                        justifyContent={'space-between'}
                    >
                        <Text>
                            {description}
                        </Text>
                        <Heading size={'sm'} mt={2}>
                            - {name}
                        </Heading>
                    </Flex>
                    {/*<Avatar*/}
                    {/*    src={imageSource}*/}
                    {/*    height={'80px'}*/}
                    {/*    width={'80px'}*/}
                    {/*    alignSelf={'center'}*/}
                    {/*    m={{ base: '0 0 35px 0', md: '0 0 0 50px' }}*/}
                    {/*/>*/}
                </Flex>
            </CardBody>
        </Card>
    );
}

export default TestimonialCard;
