import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import customTheme from './stylesheets/theme';
import Home from './pages/home';

const root = ReactDOM.createRoot(document.getElementById('root'));
const { ToastContainer } = createStandaloneToast();

root.render(
    <ChakraProvider theme={customTheme}>
        <ToastContainer />
        <Router>
            <Routes>
                <Route path='/health/ok' element={<div>ok</div>} />
                <Route path='' element={<Home />} />
                <Route path='/*' element={<Home />} />
            </Routes>
        </Router>
    </ChakraProvider>
);
